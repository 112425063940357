import style from './SideMenuBtn.module.css';

import { AiFillMedicineBox, AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoHome, IoPersonAddSharp, IoDocumentText } from "react-icons/io5";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import { RiFileExcel2Line, RiHealthBookFill } from "react-icons/ri";
import { BiSolidHelpCircle } from "react-icons/bi";
import { GiHealthNormal } from "react-icons/gi";

const iconMap = {
    'AFASTAMENTOS' : RiHealthBookFill,
    'ATENDIMENTOS' : BiSolidHelpCircle,
    'EXAMES OCUPACIONAIS' : GiHealthNormal,
    'FOLGA DE CAMPO' : FaMapMarkerAlt,
    'FOLHA DE PAGAMENTO' : FaMoneyCheckDollar,
    'GERAR RELATÓRIO' : RiFileExcel2Line,
    'PROCESSO ADMISSIONAL' : IoPersonAddSharp,
    'CONTROLE DE EXPERIÊNCIA' : IoDocumentText,
    'REPUBLICAS' : IoHome,
    'SOLICITAR ASO': AiFillMedicineBox
}

const SideMenuBtn = ({ button, clickFunc, isLoading }) => {

    const IconComponent = iconMap[ button ]

    return(
        <div className={ style.sideMenuBtn } onClick={ clickFunc } >
            <div className={ style.divIcon } >
                { isLoading ? <AiOutlineLoading3Quarters className={ style.spinner } /> : <IconComponent /> }
            </div>
            <p>{ button }</p>
        </div>
    )

}

export default SideMenuBtn;