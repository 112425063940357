import Cookies from "js-cookie";
import LoadingScreen from "../Componentes/LoadingScreen";
import NavBar from "../Componentes/NavBar";
import ExamsBody from "../Componentes/ExamsBody";

import { useEffect, useState, useRef, useCallback } from "react"
import { UseAuth } from "../Funcoes/UseAuth";
import { Navigate } from "react-router-dom";
import { format } from 'date-fns';
import * as XLSX from 'xlsx';
import Overlay from "../Componentes/Overlay";
import SideMenu from "../Componentes/SideMenu";
import SideMenuBtn from "../Componentes/SideMenuBtn";
import FormContainer from "../Componentes/FormContainer";
import Formulario from "../Componentes/Formulario";
import FormComp from "../Componentes/FormComp";
import axios from "axios";
import CheckboxesList from "../Componentes/CheckboxesList";
import CheckBoxSquare from "../Componentes/CheckBoxSquare";
import { formatarData } from "../Funcoes/FormatarData";

const ExamesOcupa = () => {

    const [loadingScreen, setLoadingScreen] = useState(true);
    const [auth, setAuth] = useState(null);
    const [obras, setObras] = useState([]);
    const [funcoes, setFuncoes] = useState([]);
    const [loadingSave, setLoadingSave] = useState(false);

    const [overlay, setOverlay] = useState(false);
    const [overlayTest, setOverlayTest] = useState('sidebar')

    const [examList, setExamList] = useState([
        { exam: 'ACUIDADE VISUAL', check: false, disabled: true },
        { exam: 'AUDIOMETRIA', check: false, disabled: true },
        { exam: 'AVALIAÇÃO PSICOSSOCIAL', check: false, disabled: true },
        { exam: 'COPROCULTURA', check: false, disabled: true },
        { exam: 'ECG', check: false, disabled: true },
        { exam: 'EEG', check: false, disabled: true },
        { exam: 'EEG / ECG', check: false, disabled: true },
        { exam: 'EXAME CLÍNICO', check: false, disabled: true },
        { exam: 'ESPIROMETRIA', check: false, disabled: true },
        { exam: 'GAMA GT', check: false, disabled: true },
        { exam: 'GLICEMIA', check: false, disabled: true },
        { exam: 'GLICEMIA JEJUM', check: false, disabled: true },
        { exam: 'HEMOGRAMA COMPLETO', check: false, disabled: true },
        { exam: 'MICOLOGICO', check: false, disabled: true },
        { exam: 'PPF', check: false, disabled: true },
        { exam: 'RX TÓRAX PA', check: false, disabled: true },
        { exam: 'SOROLOGIA SIFILIS', check: false, disabled: true },
        { exam: 'TOXICOLÓGICO', check: false, disabled: true }
    ])

    const [formValues, setFormValues] = useState({
        obra: '',
        tipo_aso: '',
        cod_aso: '',
        cpf: '',
        nome: '',
        funcao: '',
        nova_funcao: '',
        clinica: '',
        data_aso: '',
        identificador: '',
        data_realizada: null
    })
    const [formFunc, changeFormFunc] = useState('')

    const [nome, setNome] = useState('');
    const [funcao, setFuncao] = useState('');

    const [listaAso, setListaAso] = useState([]);
    const [asoFiltered, setAsoFiltered] = useState([]);
    const [loadingData, setLoadingData] = useState(false);

    const getListAso = async () => {

        setLoadingData(true);

        try {

            const { data } = await axios.get('https://sistemaintegrado.palmont.com.br/Painel/API/GetAsoList/');
            setListaAso(data);
            setAsoFiltered(data);

            console.log(data)

        } catch (error) {

            console.log(error)

        } finally {

            setLoadingData(false);

        }

    }

    useEffect(() => {

        getListAso();

    }, [])

    const [clinicas, setClinicas] = useState([])

    useEffect(() => {

        const getClinicas = async () => {

            try {

                const { data } = await axios.get('https://sistemaintegrado.palmont.com.br/Painel/API/GetClinicas/');
                setClinicas(data);

            } catch (error) {

                console.log(error)

            }

        }

        getClinicas();

    }, [])

    const handleFilter = useCallback((nomeFilter, cpfFilter, obraFilter, funcFilter, tipoFilter, statusFilter, startDate, endDate) => {

        const filtered = listaAso.filter(item => {

            const data = new Date(item.data)

            const name_filter = !nomeFilter || item.nome.includes(nomeFilter.toUpperCase());
            const cpf_filter = !cpfFilter || item.cpf.includes(cpfFilter);
            const obra_filter = !obraFilter || item.obra === obraFilter;
            const func_filter = !funcFilter || item.funcao === funcFilter;
            const tipo_filter = !tipoFilter || item.aso === tipoFilter;
            const status_filter = !statusFilter || item.status === statusFilter;
            const inicio_filter = !startDate || data >= new Date(startDate)
            const final_filter = !endDate || data <= new Date(endDate)

            return name_filter && cpf_filter && obra_filter && func_filter && tipo_filter && status_filter && inicio_filter && final_filter;

        })

        setAsoFiltered(filtered)

    }, [listaAso])

    const handleChange = (field) => (event) => {

        let { value } = event.target;

        if (field === 'cpf') {
            value = value.replace(/\D/g, '');

            if (value.length > 3) value = value.replace(/^(\d{3})(\d)/, '$1.$2');
            if (value.length > 7) value = value.replace(/^(\d{3})\.(\d{3})(\d)/, '$1.$2.$3');
            if (value.length > 11) value = value.replace(/^(\d{3})\.(\d{3})\.(\d{3})(\d)/, '$1.$2.$3-$4');

            value = value.slice(0, 14);
        }

        let cod_aso = '';

        if (field === 'tipo_aso') {
            switch (value) {
                case 'ADMISSIONAL':
                    cod_aso = '0';
                    break;
                case 'PERIÓDICO':
                    cod_aso = '1';
                    break;
                case 'RETORNO AO TRABALHO':
                    cod_aso = '2';
                    break;
                case 'DEMISSIONAL':
                    cod_aso = '9';
                    break;
                case 'MUDANÇA DE FUNÇÃO':
                    cod_aso = '3';
                    break;
                default:
                    cod_aso = '';
            }
        }

        setFormValues((prev) => ({

            ...prev,
            [field]: value,
            ...(field === 'tipo_aso' && { cod_aso }),

        }));

    };

    const prevFormValuesRef = useRef();

    useEffect(() => {

        const prevFormValues = prevFormValuesRef.current;

        if (prevFormValues) {

            const keysToCheck = ['cpf', 'obra', 'tipo_aso', 'nova_funcao'];
            const hasRelevantChange = keysToCheck.some(
                key => formValues[key] !== prevFormValues[key]
            );

            if (hasRelevantChange) {
                const isValidForm = formValues.cpf.length === 14 && formValues.obra !== '' && formValues.tipo_aso !== '';
                const needsFuncao = formValues.cod_aso === '3';

                if (isValidForm && (!needsFuncao || formValues.nova_funcao !== "")) {
                    const cpf = formValues.cpf.replace(/[.-]/g, '');
                    const { cod_aso, obra, nova_funcao } = formValues;

                    searchGHE(cpf, cod_aso, obra, nova_funcao);

                } else {

                    resetFormState();

                }
            }
        }

        prevFormValuesRef.current = formValues;

    }, [formValues]);

    const searchGHE = async (cpf, aso, obra, func) => {

        if (formFunc !== 'prepare') {

            setLoadingSave(true);

            try {
                const { data } = await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/GetExams/', {
                    cpf,
                    tipo_aso: aso,
                    obra,
                    nova_func: func
                });

                if (data.STATUS === "SUCCESS") {
                    setNome(data.COLAB.NOME);
                    setFuncao(data.COLAB.FUNCAO);

                    const examNames = data.EXAMES.map(exam => exam.EXAME);

                    const updatedExams = examList.map(exam => ({
                        ...exam,
                        check: examNames.includes(exam.exam),
                        disabled: formValues.cod_aso !== "2" || examNames.includes(exam.exam)
                    }));

                    setExamList(updatedExams);

                } else {

                    resetFormState();

                }

            } catch (error) {

                console.log(error);
                resetFormState();

            } finally {

                setLoadingSave(false);

            }

        }

    };

    const resetFormState = () => {

        setNome('');
        setFuncao('');

        const updatedExams = examList.map(exam => ({
            ...exam,
            check: false,
            disabled: true
        }));

        setExamList(updatedExams);

    };

    const resetFormValues = () => {

        setFormValues({
            obra: '',
            tipo_aso: '',
            cod_aso: '',
            cpf: '',
            nome: '',
            funcao: '',
            nova_funcao: '',
            clinica: '',
            data_aso: ''
        })

        const updatedExams = examList.map(exam => ({
            ...exam,
            check: false,
            disabled: true
        }));

        setExamList(updatedExams);

    }

    useEffect(() => {

        console.log(formFunc)

    }, [formFunc])

    const openEdit = () => {

        changeFormFunc('prepare')
        setOverlay(true);
        setOverlayTest('formulario')

    }

    useEffect(() => {

        setFormValues(prev => ({

            ...prev,
            nome: nome,
            funcao: funcao

        }));

    }, [nome, funcao])

    const handleCheckClick = (index) => {

        setExamList(prev => {

            const newList = [...prev];

            newList[index] = { ...newList[index], check: !newList[index].check };
            return newList;

        });

    };

    const handleClickOverlay = () => {

        setOverlayTest('sidebar')

    }

    const token = Cookies.get('token');

    useEffect(() => {

        const fetchUser = async () => {

            try {

                setAuth(await UseAuth(token))

            } catch (error) {

                console.log(error);

            } finally {

                setLoadingScreen(false)

            }

        }

        const getObras = async () => {

            try {

                const { data } = await axios.get('https://sistemaintegrado.palmont.com.br/Painel/API/GetObrasAtivas/')

                let obrasList = data.map(obra => obra.OBRA)
                setObras(obrasList);


            } catch (error) {

                console.log(error)

            }

        }

        const getFuncs = async () => {

            try {

                const { data } = await axios.get('https://sistemaintegrado.palmont.com.br/Painel/API/GetFunctions/')
                setFuncoes(data);

            } catch (error) {

                console.log(error)

            }

        }

        getFuncs();
        fetchUser();
        getObras();

    }, [token])

    if (loadingScreen) {

        return (<LoadingScreen />)

    }

    if (!auth || auth.STATUS !== "SUCCESS") {

        Cookies.remove('token');
        return <Navigate to={'/Login'} replace />

    }

    const user = auth.USER

    const tipoAso = [
        "ADMISSIONAL",
        "PERIÓDICO",
        "RETORNO AO TRABALHO",
        "DEMISSIONAL",
        "MUDANÇA DE FUNÇÃO"
    ]

    const exportExcel = (data) => {
        const newData = data.flatMap(item =>
            item.exames.map(obj => ({
                "COLABORADOR": item.nome,
                "CPF": item.cpf,
                "FUNÇÃO": item.funcao,
                "CENTRO CUSTO": item.obra,
                "DATA": formatarData(item.data),
                "EXAME": obj.EXAME,
                "VALOR": parseFloat(obj.VALOR),
                "CLÍNICA": item.clinica
            }))
        );

        const worksheet = XLSX.utils.json_to_sheet(newData);
        const workbook = XLSX.utils.book_new();

        const range = XLSX.utils.decode_range(worksheet['!ref']);
        for (let row = range.s.r + 1; row <= range.e.r; row++) {
            const cellAddress = XLSX.utils.encode_cell({ r: row, c: 6 });
            if (!worksheet[cellAddress]) continue;
            worksheet[cellAddress].z = 'R$ #,##0.00';
        }

        XLSX.utils.book_append_sheet(workbook, worksheet, "Exames");
        XLSX.writeFile(workbook, "Exames.xlsx");
    };

    const formASO = () => {

        return (

            <FormContainer closeFunc={setOverlay} changeContent={handleClickOverlay} loadingSave={loadingSave} >

                <Formulario valores={formValues} exames={examList} user={user} setLoadingSave={setLoadingSave} formFunc={formFunc} refreshList={getListAso} changeState={setOverlay}  >

                    <FormComp
                        title={"CENTRO CUSTO"}
                        type={'select'}
                        options={obras}
                        valor={formValues.obra}
                        changeFunc={handleChange('obra')}
                        disabled={formFunc === 'edit' ? true : false}
                    />
                    <FormComp
                        title={"TIPO DE ASO"}
                        type={'select'}
                        options={tipoAso}
                        valor={formValues.tipo_aso}
                        changeFunc={handleChange('tipo_aso')}
                        disabled={formFunc === 'edit' ? true : false}
                    />
                    <FormComp
                        title={"CPF"}
                        type={'text'}
                        valor={formValues.cpf}
                        changeFunc={handleChange('cpf')}
                        readOnly={false}
                        disabled={formFunc === 'edit' ? true : false}
                    />
                    <FormComp
                        title={"NOME"}
                        type={'text'}
                        valor={formValues.nome}
                        readOnly={true}
                        disabled={formFunc === 'edit' ? true : false}
                    />

                    {

                        formValues.cod_aso === '3'
                            ? (
                                <FormComp
                                    title={"FUNÇÃO"}
                                    type={'select'}
                                    options={funcoes}
                                    valor={formValues.nova_funcao}
                                    changeFunc={handleChange('nova_funcao')}
                                    disabled={formFunc === 'edit' ? true : false}
                                />
                            ) : (
                                <FormComp
                                    title={"FUNÇÃO"}
                                    type={'text'}
                                    valor={formValues.funcao}
                                    readOnly={true}
                                    disabled={formFunc === 'edit' ? true : false}
                                />
                            )

                    }

                    <CheckboxesList>

                        <p>LISTA DE EXAMES</p>

                        {examList.map((obj, index) => (
                            <CheckBoxSquare
                                key={index}
                                label={obj.exam}
                                checked={obj.check}
                                disabled={obj.disabled}
                                onChange={() => handleCheckClick(index)}
                            />
                        ))}
                    </CheckboxesList>

                    <FormComp
                        title={"CLÍNICA"}
                        type={'select'}
                        options={clinicas.sort()}
                        valor={formValues.clinica}
                        changeFunc={handleChange('clinica')}
                        disabled={formFunc === 'edit' ? true : false}
                    />

                    <FormComp
                        title={"DATA DO EXAME"}
                        type={'date'}
                        valor={formValues.data_aso}
                        changeFunc={handleChange('data_aso')}
                        disabled={formFunc === 'edit' ? true : false}
                    />

                    <FormComp
                        title={"EXAME REALIZADO"}
                        type={'select'}
                        options={['SIM']}
                        valor={formValues.data_realizada ? 'SIM' : ''}
                        changeFunc={handleChange('data_realizada')}
                        disabled={formValues.data_realizada ? true : false}
                    />

                </Formulario>

            </FormContainer>

        );

    }

    const sidebar = () => {
        return (
            <SideMenu>
                <SideMenuBtn
                    button={"SOLICITAR ASO"}
                    clickFunc={() => {
                        setOverlayTest('formulario')
                        changeFormFunc('create')
                        resetFormValues();
                    }} />

                <SideMenuBtn
                    button={"GERAR RELATÓRIO"}
                    clickFunc={() => exportExcel(asoFiltered)}
                />
            </SideMenu>
        );
    }

    return (
        <>
            <NavBar
                title="EXAMES OCUPACIONAIS"
                state={overlay}
                changeState={setOverlay}
                setSideBar={sidebar}
            />

            <ExamsBody
                dados={asoFiltered}
                formulario={formValues}
                changeFormulario={setFormValues}
                showOverlay={openEdit}
                changeFormFunc={changeFormFunc}
                examList={examList}
                setExamList={setExamList}
                onFilter={handleFilter}
                loading={loadingData}
                changeState={setOverlay}
            />

            <Overlay
                state={overlay}
                changeState={setOverlay}
                changeContent={handleClickOverlay}
            >
                {overlayTest === 'sidebar' ? sidebar() : formASO()}
            </Overlay>
        </>
    )
}

export default ExamesOcupa;