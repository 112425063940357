import axios from 'axios';
import styles from './NewDistanceTable.module.css'
import { useState } from 'react';

const NewDistanceTable = ({ formValues, handleChange, handleChangeValue, closeForm, resetValues }) => {

    const [ btnText, setBtnText ] = useState( 'SALVAR' );
    const [ disabled, setDisabled ] = useState( false );

    const saveDistance = async ( e ) => {

        setBtnText("CARREGANDO...");
        setDisabled( true );

        e.preventDefault();

        if( !formValues.DIAS_PRAZO ){

            handleChange( 'DIAS_PRAZO' , 90 )

        }
        
        try{

            const { data } = await axios.post('http://localhost/Painel/API/SaveNewDistance/', formValues );
            console.log( data );

            if( data.STATUS === "SUCCESS" ){

                closeForm();
                resetValues();

            }

        }catch( error ){

            alert( error )

        }finally{
            setBtnText( 'SALVAR' );
            setDisabled( false );
        }

    }

    return (
        <div className={ styles.tableDistance } onClick={ ( e ) => { e.stopPropagation() } } >
            <form>
                <div className={ styles.formDiv } >
                    <p>CENTRO CUSTO</p>
                    <input value={ formValues.CENTRO_CUSTO } type='text' disabled />
                </div>

                <div className={ styles.formDiv } >
                    <p>MUNICIPIO</p>
                    <input value={ formValues.MUNICIPIO + ', ' + formValues.ESTADO } type='text' disabled />
                </div>

                <div className={ styles.formDiv } >
                    <p>DISTANCIA ( KM )</p>
                    <input
                        required
                        value={ formValues.DISTANCIA }
                        type='text'
                        onBlur={ () => { handleChangeValue() } }
                        onChange={ ( e ) =>  handleChange( 'DISTANCIA' , e.target.value ) }
                    ></input>
                </div>

                <div className={ styles.formDiv } >
                    <p>VALOR</p>
                    <input value={ formValues.VALOR } type='text' disabled />
                </div>

                <div className={ styles.formDiv } >
                    <p>DIAS PRAZO</p>
                    <input value={ formValues.DIAS_PRAZO } type='text' disabled />
                </div>

                <div className={ styles.formDiv } >
                    <p>DIAS GOZO</p>
                    <input value={ formValues.DIAS_GOZO } type='text' disabled />
                </div>

                <div className={ styles.formDiv } >
                    <button disabled={ disabled } onClick={ ( e ) => saveDistance( e ) } >{ btnText }</button>
                </div>

            </form>
        </div>
    )
}

export default NewDistanceTable;