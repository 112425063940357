import { useState } from 'react';
import styles from './ConfirmPeriodo.module.css';

import axios from 'axios';

const ConfirmPeriodo = ({ dados, exp, user, cancel, resetData }) => {

    const [ btnText, setBtnText ] = useState( 'SIM' )
    const [ disabled, setDisabled ] = useState( false )

    const openExp = async ( dados, exp ) => {

        setBtnText( 'CARREGANDO...' );
        setDisabled( true );

        const payload = {

            dados: dados,
            exp: exp,
            user: user

        }

        try {
            
            const { data } = await axios.post( 'http://localhost/Painel/API/CtrlExpPDF/', payload, {
                responseType: 'blob'
            })

            const fileURL = window.URL.createObjectURL( new Blob( [ data ] ) )

            const link = document.createElement( 'a' );
            link.href = fileURL;
            link.setAttribute( 'download', 'CONTROLE_DE_EXP.pdf' )
            document.body.appendChild( link );
            link.click();

            document.body.removeChild( link );

            resetData();
            
        } catch (error) {

            alert( error.message );
            
        }finally{

            setBtnText( 'SIM' )
            setDisabled( false );

        }

    }

    return( 
        <div className={ styles.container } onClick={ ( e ) => { e.stopPropagation() } } >
            <p>REALMENTE DESEJA GERAR A EFETIVAÇÃO DO COLABORADOR ?</p>

            <div className={ styles.containerBtns } >
                <button className={ styles.btnConfirm } onClick={ () => { openExp( dados, exp ) } } disabled={ disabled } >{ btnText }</button>
                <button className={ styles.btnCancel } onClick={ () => { cancel() } } >NÃO</button>
            </div>
        </div>
    )

}

export default ConfirmPeriodo;