import NavBar from "../Componentes/NavBar";
import RepsBody from "../Componentes/RepsBody";

const NewReps = () => {

    return(
        <>
            <NavBar
                title="REPÚBLICAS"
            />
            
            <RepsBody />

        </>
    )

}

export default NewReps;