import ColabBody from "../Componentes/ColabBody";
import NavBar from "../Componentes/NavBar";

const Colab = () => {

    return(
        <>
            <NavBar />
            <ColabBody />
        </>
    );

}

export default Colab;