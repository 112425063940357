import style from './RepsBody.module.css';

const RepsBody = () => {
    return(
        <div className={ style.bodyRep } >

            <div className={ style.repHeader } >
                <div className={ style.repFilters } >
                    <div className={ style.repFilterContainer } ></div>
                    <div className={ style.repFilterContainer } ></div>
                    <div className={ style.repFilterContainer } ></div>
                    <div className={ style.repFilterContainer } ></div>
                    <div className={ style.repFilterContainer } ></div>
                    <div className={ style.repFilterContainer } ></div>
                    <div className={ style.repFilterContainer } ></div>
                    <div className={ style.repFilterContainer } ></div>
                </div>
                <div className={ style.repStats } ></div>
            </div>

            <div className={ style.repSquare } ></div>

        </div>
    )
}

export default RepsBody;