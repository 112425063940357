import ColabInfoChild from '../ColabInfoChild';
import styles from './ColabBody.module.css';

const ColabBody = () => {
    return (
        <div className={styles.colabBody} >
            <div className={styles.colabInfo} >

                <div className={styles.colabPicContainer}>
                    <div className={styles.colabPic} ></div>
                </div>

                <div className={styles.colabContainerInfo} >
                    <ColabInfoChild
                        title="MATRICULA"
                        text="010630"
                    />
                    <ColabInfoChild
                        title="NOME"
                        text="010630"
                    />
                    <ColabInfoChild
                        title="CPF"
                        text="010630"
                    />
                    <ColabInfoChild
                        title="CENTRO DE CUSTO"
                        text="010630"
                    />
                    <ColabInfoChild
                        title="FUNÇÃO"
                        text="010630"
                    />
                    <ColabInfoChild
                        title="DATA ADMISSÃO"
                        text="010630"
                    />
                    <ColabInfoChild
                        title="DATA DEMISSÃO"
                        text="010630"
                    />
                    <ColabInfoChild
                        title="MÃO DE OBRA"
                        text="010630"
                    />
                    <ColabInfoChild
                        title="SEXO"
                        text="010630"
                    />
                    <ColabInfoChild
                        title="DATA DE NASCIMENTO"
                        text="010630"
                    />
                    <ColabInfoChild
                        title="SITUAÇÃO"
                        text="010630"
                    />
                    <ColabInfoChild
                        title="RG"
                        text="010630"
                    />
                    <ColabInfoChild
                        title="UF DO RG"
                        text="010630"
                    />
                    <ColabInfoChild
                        title="MUNICIPIO DE RESIDÊNCIA"
                        text="010630"
                    />
                    <ColabInfoChild
                        title="PIS"
                        text="010630"
                    />
                    <ColabInfoChild
                        title="CONTATO"
                        text="010630"
                    />
                </div>

            </div>
            <div className={styles.colabFill} ></div>
        </div>
    )
}

export default ColabBody;