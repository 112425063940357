import style from './FormComp.module.css';

const FormComp = ({ title, type, options, valor, changeFunc, readOnly, disabled }) => {

    return(
        
        <div className={ style.formChild } >

            <p>{ title }</p>

            { type === 'select' 
                ?
                (
                    <select value={ valor } onChange={ changeFunc } disabled={ disabled } >

                        <option value={''} >SELECIONE...</option>

                        { options.map( ( opt, index ) => (
                            <option value={ opt } key={ index } >{ opt }</option>
                        ) ) }

                    </select>
                )
                :
                (
                    <input type={ type } value={ valor } onChange={ changeFunc } readOnly={ readOnly } disabled={ disabled } />
                )
            }

        </div>

    )

}

export default FormComp;