import HomeBody from "../Componentes/HomeBody";
import NavBar from "../Componentes/NavBar";
import LoadingScreen from "../Componentes/LoadingScreen";
import Overlay from "../Componentes/Overlay";
import SideMenu from "../Componentes/SideMenu";
import SideMenuBtn from "../Componentes/SideMenuBtn";

import { useCallback, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import axios from "axios";

const NewHome = () => {

    const navigate = useNavigate();

    const [loadingScreen, setLoadingScreen] = useState(true);
    const [auth, setAuth] = useState(null);
    const [loadingRelat, setLoadingRelat] = useState( false );

    const [overlay, setOverlay] = useState(false);

    const [colabs, setColabs] = useState([]);
    const [colabsFilter, setColabsFilter] = useState([]);

    const funcoes = [...new Set(colabs.map(item => item.FUNCAO).filter(FUNCAO => FUNCAO).sort())]
    const obras = [...new Set(colabs.map(item => item.CENTRO_CUSTO).filter(CENTRO_CUSTO => CENTRO_CUSTO).sort())]
    const encarregados = [...new Set(colabs.map(item => item.ENCARREGADO).filter(ENCARREGADO => ENCARREGADO).sort())]
    const contratos = [...new Set(colabs.map(item => item.CONTRATO).filter(CONTRATO => CONTRATO).sort())]

    const filterDados = useCallback((filterName, filterMat, filterFunc, filterEnc, filterObra, filterCont, filterStatus, filterMaoObra) => {

        const filtered = colabs.filter(item => {

            const name_filter = !filterName || item.NOME.toUpperCase().includes(filterName.toUpperCase());
            const mat_filter = !filterMat || item.MATRICULA.includes(filterMat)

            const func_filter = filterFunc.length === 0 || filterFunc.some(func => item.FUNCAO.includes(func.value));
            const enc_filter = filterEnc.length === 0 || filterEnc.some(enc => (item.ENCARREGADO || '').includes(enc.value));
            const obra_filter = filterObra.length === 0 || filterObra.some(obra => item.CENTRO_CUSTO.includes(obra.value));
            const cont_filter = filterCont.length === 0 || filterCont.some(cont => (item.CONTRATO || '').includes(cont.value));
            const stat_filter = filterStatus.length === 0 || filterStatus.some(stat => item.STATUS.includes(stat.value));
            const mao_filter = filterMaoObra.length === 0 || filterMaoObra.some(mao => item.MAOOBRA.includes(mao.value));

            return name_filter && mat_filter && func_filter && enc_filter && obra_filter && cont_filter && stat_filter && mao_filter;

        })

        console.log( filtered )

        setColabsFilter(filtered);

    }, [colabs])

    const token = Cookies.get('token');

    useEffect(() => {
        const authenticate = async () => {
            try {
                const { data } = await axios.get('https://sistemaintegrado.palmont.com.br/Painel/API/AuthToken/', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                console.log(data)
                setAuth(data);

            } catch (error) {

                console.log('Erro ao autenticar:', error);
                setAuth(null);

            } finally {
                setLoadingScreen(false);
            }
        }

        if (token) {
            authenticate();
        } else {
            setLoadingScreen(false);
        }

    }, [token]);

    useEffect(() => {

        const getColabs = async () => {

            try {

                const { data } = await axios.get('https://sistemaintegrado.palmont.com.br/Painel/API/GetHomeColabs/');

                setColabs(data);
                setColabsFilter(data);

            } catch (error) {

                console.log(error);

            }

        }

        getColabs();

    }, [])

    const generateRPE = async () => {

        setLoadingRelat( true );

        try{

            const { data } = await axios.post("http://localhost/Painel/API/GenerateRPE/", colabsFilter, {
                responseType: 'blob'
            });
            
            const url = window.URL.createObjectURL( new Blob([ data ]) );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Relatório.xlsx');
            document.body.appendChild( link );
            link.click();
            document.body.removeChild( link );

        }catch( error ){

            alert( error.message );

        }finally{

            setLoadingRelat( false );

        }

    }

    console.log( auth )

    const showOverlay = () => {

        setOverlay(true);

    }

    if (loadingScreen) {
        return <LoadingScreen />;
    }

    if (!auth) {
        return <Navigate to="/login" />;
    }

    return (
        <>
            <NavBar
                title="HOME"
                changeState={showOverlay}
            />
            <HomeBody
                dados={colabsFilter}
                funcoes={funcoes}
                obras={obras}
                encarregados={encarregados}
                contratos={contratos}
                onFilter={filterDados}
            />
            <Overlay
                state={overlay}
                changeState={setOverlay}
            >
                <SideMenu>
                    <SideMenuBtn
                        button="AFASTAMENTOS"
                        clickFunc={() => { navigate('/Afastamentos') }}
                    />
                    <SideMenuBtn
                        button="ATENDIMENTOS"
                    />
                    <SideMenuBtn
                        button="CONTROLE DE EXPERIÊNCIA"
                        clickFunc={() => { navigate('/Recisoes') }}
                    />
                    <SideMenuBtn
                        button="EXAMES OCUPACIONAIS"
                        clickFunc={() => { navigate('/ExamOcup') }}
                    />

                    { /* <SideMenuBtn
                        button="FOLGA DE CAMPO"
                        clickFunc={() => { navigate('/FolgaCampo') }}
                    /> */ }

                    { auth.ACCESS_LVL ===  1 ? (
                        <SideMenuBtn
                            button="FOLHA DE PAGAMENTO"
                            clickFunc={ () => { navigate('/FolhaPag') } }
                        />
                    ) : ( <></> ) }

                    <SideMenuBtn
                        button="GERAR RELATÓRIO"
                        isLoading={ loadingRelat }
                        clickFunc={ generateRPE }
                    />
                    <SideMenuBtn
                        button="PROCESSO ADMISSIONAL"
                        clickFunc={() => { navigate('/ProcessoAdm') }}
                    />
                    <SideMenuBtn
                        button="REPUBLICAS"
                        clickFunc={() => { navigate('/Republicas') }}
                    />

                </SideMenu>
            </Overlay>
        </>
    );
}

export default NewHome;