import FolgaBody from "../Componentes/FolgaBody";
import NavBar from "../Componentes/NavBar";
import axios from "axios";

import { useCallback, useEffect, useState } from "react";
import Overlay from "../Componentes/Overlay";
import NewDistanceTable from "../Componentes/NewDistanceTable";

const FolgaCampo = () => {

    const [ loadingTable, setLoadingTable ] = useState( true );

    const [ valores, setValores ] = useState([])
    const [ filterdValues, setFilteredValues ] = useState([])

    const [ overlay, setOverlay ] = useState( false )
    const [ formValues, setFormValues ] = useState({
        CENTRO_CUSTO: '',
        MUNICIPIO: '',
        ESTADO: '',
        DISTANCIA: '',
        VALOR: '',
        DIAS_GOZO: '',
        DIAS_PRAZO: '',
        IDENTIFICADOR: ''
    })

    const openForm = ( obj ) => {

        setFormValues( obj );
        setOverlay( true );

    }

    const closeForm = () => {

        setFormValues({
            CENTRO_CUSTO: '',
            MUNICIPIO: '',
            ESTADO: '',
            DISTANCIA: '',
            VALOR: '',
            DIAS_GOZO: '',
            DIAS_PRAZO: '',
            IDENTIFICADOR: ''
        })
        setOverlay( false );

    }

    const handleInputChange = (name, value) => {

        setFormValues((prevValues) => ({
          ...prevValues,
          [name]: value
        }));

    };

    const handleChangeValue = () => {

        const distancia = parseFloat( formValues.DISTANCIA );
        let valor = '';
        let dias

        if( distancia <= 300 ){

            valor = "350,00"
            dias = 0

        }else if( distancia >= 301 && distancia <= 500 ){

            valor = "500,00"
            dias = 1

        }else if( distancia >= 501 && distancia <= 1000 ){

            valor = "1000,00"
            dias = 2

        }else if( distancia >= 1001 && distancia <= 2000 ){

            valor = "1500,00"
            dias = 3

        }else if( distancia >= 2001 ){

            valor = "2000,00"
            dias = 4

        }else if( !distancia ){

            valor = "0,00"
            dias = 0

        }

        handleInputChange( 'VALOR', valor );
        handleInputChange( 'DIAS_GOZO', dias );
        handleInputChange( 'DIAS_PRAZO', 90 );

    }

    useEffect(() => {

        getValores();

    }, [])

    const getValores = async () => {

        if( !loadingTable ){

            setLoadingTable( true )

        }

        try{
            
            const { data } = await axios.get( 'http://localhost/Painel/API/GetDistances/' )
            console.log( data )

            setValores( data )
            setFilteredValues( data )
            
        }catch( error ){

            console.error( error );

        }finally{

            setLoadingTable( false );

        }

    }

    const obras = [...new Set(valores.map(item => item.CENTRO_CUSTO).filter(CENTRO_CUSTO => CENTRO_CUSTO).sort())]
    const municipios = [...new Set(valores.map(item => item.MUNICIPIO).filter(MUNICIPIO => MUNICIPIO).sort())]
    const estados = [...new Set(valores.map(item => item.ESTADO).filter(ESTADO => ESTADO).sort())]

    const filterDados = useCallback((filterObra, filterMunicip, filterEstados, filterDist) => {

        const filtered = valores.filter(item => {
            
            const obra_filter = filterObra.length === 0 || filterObra.some(obra => item.CENTRO_CUSTO?.includes(obra.value));
            const municip_filter = filterMunicip.length === 0 || filterMunicip.some(municip => item.MUNICIPIO?.includes(municip.value));
            const estados_filter = filterEstados.length === 0 || filterEstados.some(estado => item.ESTADO?.includes(estado.value));

            const dist_filter = () => {
                if ( !filterDist ) return true;
                
                const distance = item.DISTANCIA || 0;
    
                switch (filterDist) {
                    case '0 - 300':
                        return distance >= 0 && distance <= 300;
                    case '301 - 500':
                        return distance >= 301 && distance <= 500;
                    case '501 - 1000':
                        return distance >= 501 && distance <= 1000;
                    case '1001 - 2000':
                        return distance >= 1001 && distance <= 2000;
                    case '2001 >':
                        return distance >= 2001;
                    default:
                        return false;
                }
            };
    
            return obra_filter && municip_filter && estados_filter && dist_filter();
        });
    
        setFilteredValues(filtered);
        
    });

    return(
        <>
            <NavBar
                title="FOLGAS DE CAMPO"
            />
            <FolgaBody
                dados={ filterdValues }
                obras={ obras }
                municipios={ municipios }
                estados={ estados }
                onFilter={ filterDados }
                selectObj={ openForm }
                loadingValues={ loadingTable }
            />
            <Overlay
                state={ overlay }
                changeContent={ closeForm }
            >
                <NewDistanceTable
                    formValues={ formValues }
                    handleChange={ handleInputChange }
                    handleChangeValue={ handleChangeValue }
                    closeForm={ closeForm }
                    resetValues={ getValores }
                />
            </Overlay>
        </>
    )

}

export default FolgaCampo;